import "./src/styles/global.css"

const appendScript = (url) => {
  const script = document.createElement('script')
  script.src = url
  script.defer = script.async = true
  script.crossorigin = 'anonymous'
  document.head.appendChild(script)
}

const appendAdSense = () => {
  if (!document.querySelector('.adsbygoogle')) return
  if ((window.adsbygoogle || {}).loaded) return

  appendScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3703878768228760')
}

const appendExternalScripts = () => {
  appendAdSense()
}

export const onRouteUpdate = () => {
  window.addEventListener('scroll', appendExternalScripts, { once: true })
}
